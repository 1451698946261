import React from "react";
import {ContractDataOptions, ContractProductOptions, CustomerDataOptions} from "./CustomerDetail";
import Card from "../../components/Card";
import Heading, {Level} from "../../components/Typography/Heading";
import PermissionWrapper, {
  UserPermissions,
} from "../../components/UserPermission";
import Button from "../../components/Button";
import Translate from "../../components/Translate";
import Icon from "../../components/Icon";
import DataList from "../../components/DataList";
import ContractDeleteProductModal from "./ContractDeleteProductModal";
import { organisationService } from "../../plugins/axios";

interface ContractMultiDeviceDetailCard {
  contract: ContractDataOptions;
  customer: CustomerDataOptions;
  setShowContractModal: React.Dispatch<React.SetStateAction<boolean>>;
  setContractToEditData: React.Dispatch<
    React.SetStateAction<ContractDataOptions | null>
  >;
  setShowTasksModal: React.Dispatch<React.SetStateAction<boolean>>;
  setTasksContractNumber: React.Dispatch<React.SetStateAction<string>>;
  setShowContractDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  setContractToDeleteData: React.Dispatch<
    React.SetStateAction<ContractDataOptions | null>
  >;
  setShowContractRetainModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowClaimsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setContractToRetainData: React.Dispatch<
    React.SetStateAction<ContractDataOptions | null>
  >;

  setShowAddProductModal: React.Dispatch<React.SetStateAction<boolean>>;

  handleClaimsSubmit(): void;

  contractHasClaims(contract: ContractDataOptions): boolean;

  downloadDocument(contract: string, type: string): void;
}

const ContractMultiDeviceDetailCard: React.FC<ContractMultiDeviceDetailCard> = (
  props
) => {
  const {
    contract,
    setContractToEditData,
    setShowTasksModal,
    setTasksContractNumber,
    setShowContractDeleteModal,
    setContractToDeleteData,
    setShowContractRetainModal,
    setShowClaimsModal,
    setContractToRetainData,
    setShowAddProductModal,
    handleClaimsSubmit,
    contractHasClaims,
    downloadDocument,
  } = props;

  const [showProductDeleteModal, setShowProductDeleteModal] = React.useState(false);
  const [productToDelete, setProductToDelete] = React.useState<ContractProductOptions | null>(null);
  const [allowMultiDeviceActions, setAllowMultiDeviceActions] = React.useState(false);

  const checkAllowMultiDeviceActions = async (organisationLabel: string): Promise<boolean> => {
    try {
      const response = await organisationService.get(`/organisation_templates`);
      const organisationTemplate = response.data.find((template: any) => template.label === organisationLabel);
      return organisationTemplate.allow_multi_device_actions ? organisationTemplate.allow_multi_device_actions : false;
    } catch (error) {
      console.error("Error checking allow_multi_device_actions:", error);
      return false;
    }
  };

  React.useEffect(() => {
    const fetchAllowMultiDeviceActions = async () => {
      const allow = await checkAllowMultiDeviceActions(contract.template_no);
      setAllowMultiDeviceActions(allow);
    };

    fetchAllowMultiDeviceActions();
  }, [contract.template_no]);

  const {products} = contract;

  // filter out the things we don't need in a multidevice contract
  let contractDataList = contract.data.filter(function (item) {
    return (
      item.label !== "detail.contracts.value" &&
      item.label !== "detail.contracts.imei" &&
      item.label !== "detail.contracts.serial_no"
    );
  });

  return (
    <Card classList="mt-8">
      <Card.Header cols="grid-cols-12" colSpan="col-span-full">
        <div className="col-span-full md:col-span-6">
          <Heading color="text-purple-700" tag="h3" level={Level.HEADING_3}>
            {contract.contract_description}
          </Heading>
          <Heading tag="h4" level={Level.HEADING_5}>
            {contract.contract_no}
          </Heading>
        </div>
        {allowMultiDeviceActions && (
          <div className="gap-x-4 col-span-full md:col-span-6 flex items-center justify-end">
            <PermissionWrapper
              permissionSlug={UserPermissions.EDIT_INSURANCE_POLICY}
            >
              {contract.status.toLowerCase() === "active" ? (
                <Button
                  onClick={() => {
                    setShowAddProductModal(
                      (showAddProductModal: Boolean) => !showAddProductModal
                    );
                    setContractToEditData((contractToEditData) => contract);
                  }}
                >
                  <Translate name={"detail.contracts.add_product"}/>
                </Button>
              ) : null}
            </PermissionWrapper>
          </div>
        )}
      </Card.Header>

      <div className="flex flex-col">
        <div className="p-2 w-48">
          <Icon name={contract.category}/>
        </div>
        <div className="flex-grow">
          <DataList perRow={2} data={contractDataList}/>
          <tr>
            <td className="pr-6 pt-3 whitespace-nowrap">
              <span className="text-sm font-base">
                <Translate name={"detail.contracts.premium_amount"}/>
              </span>
              <span className="text-sm pl-2 font-base">
                {contract.premium_amount ? "€" + contract.premium_amount : "-"}
              </span>
            </td>
          </tr>
          <tr>
            <td className="pr-6 whitespace-nowrap">
              <span className="text-sm font-base">
                <Translate name={"detail.contracts.own_risk_damage_amount"}/>
              </span>
              <span className="text-sm pl-2 font-base">
                {contract.own_risk_damage_amount > 0
                  ? "€" + contract.own_risk_damage_amount
                  : "-"}
              </span>
            </td>
          </tr>
          <tr>
            <td className="pr-6 whitespace-nowrap">
              <span className="text-sm font-base">
                <Translate name={"detail.contracts.own_risk_theft_amount"}/>
              </span>
              <span className="text-sm pl-2 font-base">
                {contract.own_risk_theft_amount > 0
                  ? "€" + contract.own_risk_theft_amount
                  : "-"}
              </span>
            </td>
          </tr>

          {products.map((product, id) => {
            let productData = [
              {
                label: "detail.contracts.serial_no",
                value: product.serial_number,
              },
              {
                label: "detail.contracts.imei",
                value: product.imei ? product.imei : "-",
              },
              {
                label: "detail.contracts.object_value",
                value: `€${product.object_value}`,
              },
              {
                label: "detail.contracts.manufacturer",
                value: product.manufacturer,
              },
            ];
            return (
              <div key={id}>
                <div className="flex bg-purple-50 px-8 py-2 -mx-8 mt-6 justify-between">
                  <h2>{product.description}</h2>
                  {allowMultiDeviceActions && (
                  <div className="cursor-pointer" onClick={() => {
                    setShowProductDeleteModal(true)
                    setProductToDelete(product);
                  }}><Icon classList="mt-1" name={"error"}/></div>
                  )}
                </div>
                <div>
                  <DataList perRow={2} data={productData}/>
                </div>
              </div>
            );
          })}

          <div className="flex flex-col mt-4 space-y-2">
            <PermissionWrapper permissionSlug={UserPermissions.CREATE_CLAIMS}>
              <div
                onClick={() => {
                  setContractToEditData(contract);
                  handleClaimsSubmit();
                }}
                className="text-sm text-right text-purple-700 underline cursor-pointer"
              >
                <Translate name={"detail.contracts.new_claim"}/>
              </div>
            </PermissionWrapper>

            {/* create user task */}
            <div
              className="text-sm text-right text-purple-700 underline cursor-pointer"
              onClick={() => {
                setShowTasksModal(true);
                setTasksContractNumber(contract.contract_no);
              }}
            >
              <Translate name={"detail.contracts.new_task"}/>
            </div>

            {contractHasClaims(contract) ? (
              <div
                onClick={() => {
                  setShowClaimsModal(
                    (showClaimsModal: boolean) => !showClaimsModal
                  );
                  setContractToEditData(contract);
                }}
                className="text-sm text-right text-purple-700 underline cursor-pointer"
              >
                <Translate name={"detail.contracts.view_claims"}/>
              </div>
            ) : null}
            <PermissionWrapper
              permissionSlug={UserPermissions.CANCEL_INSURANCE_POLICY}
            >
              {contract.status.toLowerCase() === "active" ? (
                <>
                  <div
                    onClick={() => {
                      setShowContractDeleteModal(
                        (showContractDeleteModal) => !showContractDeleteModal
                      );
                      setContractToDeleteData(contract);
                    }}
                    className="text-sm text-right text-purple-700 underline cursor-pointer"
                  >
                    <Translate name={"detail.contracts.cancel"}/>
                  </div>

                  <PermissionWrapper
                    permissionSlug={UserPermissions.CUSTOMER_RETENTION}
                  >
                    <div
                      onClick={() => {
                        setShowContractRetainModal(
                          (showContractRetainModal) => !showContractRetainModal
                        );
                        setContractToRetainData(contract);
                      }}
                      className="text-sm text-right text-purple-700 underline cursor-pointer"
                    >
                      <Translate name={"detail.contracts.retain"}/>
                    </div>
                  </PermissionWrapper>
                </>
              ) : null}
              <div
                className="text-sm text-right text-purple-700 underline cursor-pointer"
                onClick={() => downloadDocument(contract.contract_id, "ipid")}
              >
                <Translate name={"detail.contracts.download_ipid"}/>
              </div>
              <div
                className="text-sm text-right text-purple-700 underline cursor-pointer"
                onClick={() =>
                  downloadDocument(contract.contract_id, "policy_conditions")
                }
              >
                <Translate name={"detail.contracts.download_policy"}/>
              </div>
              <div
                className="text-sm text-right text-purple-700 underline cursor-pointer"
                onClick={() =>
                  downloadDocument(contract.contract_id, "policy_sheet")
                }
              >
                <Translate name={"detail.contracts.download_sheet"}/>
              </div>
            </PermissionWrapper>
          </div>
        </div>
      </div>

      {productToDelete && (
        <ContractDeleteProductModal
          isOpen={showProductDeleteModal}
          setIsOpen={setShowProductDeleteModal}
          product={productToDelete}
          contract={contract}
          customer={props.customer}
        />
      )}

    </Card>
  );
};

export default ContractMultiDeviceDetailCard;
